<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGarden.return_one') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
          <b-row v-if="!isGardenAdmin">
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Area Type" vid="area_type_id" v-slot="{ errors }">
                  <b-form-group
                      label-for="area_type_id"
                  >
                  <template v-slot:label>
                      {{$t('teaGardenConfig.area_type')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.area_type_id"
                      :options="getAreaTypeList"
                      id="area_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col v-if="search.area_type_id" xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Division" vid="division_id" v-slot="{ errors }">
                  <b-form-group
                      label-for="division_id"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.division')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.division_id"
                      :options="divisionList"
                      id="division_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col v-if="search.area_type_id" xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="District" vid="district_id" v-slot="{ errors }">
                  <b-form-group
                      label-for="district_id"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.district')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.district_id"
                      :options="districtList"
                      id="district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                      </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 1">
                  <ValidationProvider name="City Corporation" vid="city_corporation_id" v-slot="{ errors }">
                  <b-form-group
                      label-for="city_corporation_id"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.city_corporation')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.city_corporation_id"
                      :options="cityCorporationList"
                      id="city_corporation_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 2 || search.area_type_id === 3">
                  <ValidationProvider name="Upazila" vid="upazila_id" v-slot="{ errors }">
                  <b-form-group
                      label-for="upazila_id"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.upazila')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.upazila_id"
                      :options="upazilaList"
                      id="upazila_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 2">
                  <ValidationProvider name="Pauroshoba" vid="pauroshoba_id" v-slot="{ errors }">
                  <b-form-group
                      label-for="pauroshoba_id"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.pouroshova')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.pauroshoba_id"
                      :options="pauroshobaList"
                      id="pauroshoba_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.area_type_id === 3">
                  <ValidationProvider name="Union" vid="union_id" v-slot="{ errors }">
                  <b-form-group
                      label-for="union_id"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.union')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.union_id"
                      :options="unionList"
                      id="union_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Year" vid="year" rules="" v-slot="{ errors }">
                  <b-form-group
                      label-for="year"
                  >
                  <template v-slot:label>
                      {{$t('globalTrans.year')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.year"
                      :options="yearList"
                      id="year"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Type Garden Factory" vid="type_garden_factory_id" rules="" v-slot="{ errors }">
                  <b-form-group
                      label-for="type_garden_factory_id"
                  >
                  <template v-slot:label>
                      {{$t('teaGarden.type_garden_factory')}}
                  </template>
                  <b-form-select
                      :disabled="isGardenAdmin"
                      plain
                      v-model="search.return_type"
                      :options="returnTypeList"
                      id="type_garden_factory_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Tea Garden Factory Name" vid="garden_factory_id" v-slot="{ errors }">
                  <b-form-group
                      label-for="garden_factory_id"
                  >
                  <template v-slot:label>
                      {{$t('teaGarden.garden_factory_name')}}
                  </template>
                  <b-form-select
                      :disabled="isGardenAdmin"
                      plain
                      v-model="search.garden_fact_id"
                      :options="gardenList"
                      id="garden_factory_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                  </ValidationProvider>
              </b-col>
              <b-col sm="4">
                  <b-button type="button" @click="searchData" size="sm" variant="primary">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
              </b-col>
          </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.return_one') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link to="return-one-entry" class="btn-add"><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col sm="12">
                <div class="quick-filter-wrapper">
                  <div class="quick-filter-left">
                    <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                      <b-dropdown-form>
                        <div
                          class="form-group"
                          v-for="(field, index) in labelData"
                          :key="index"
                          >
                            <b-form-checkbox
                              :id="'checkbox-' + field.labels"
                              v-model="field.show"
                              :name="'checkbox-' + field.labels"
                              value=1
                              unchecked-value=0
                            >
                              {{ $t(field.labels)  }}
                            </b-form-checkbox>
                        </div>
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                  <div class="quick-filter-right">
                    <b-form-group
                      :label="$t('menu.perpage')"
                      label-for="per-page-select"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="search.limit"
                        :options="pageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(year)="data">
                    {{ $n(data.item.year, { useGrouping: false }) }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-warning" v-if="data.item.status === 1">{{$t('globalTrans.draft')}}</span>
                    <span class="badge badge-success" v-else>{{$t('globalTrans.submitted')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button :title="$t('globalTrans.details')" v-b-modal.modal-4 class="moc-action-btn moc-view-btn" size="sm" @click="detailsData(data.item)"><i class="ri-eye-fill"></i></b-button>
                    <router-link v-if="data.item.status === 1" class="action-btn edit" :to="`return-one-entry?id=${data.item.id}`" :title="$t('globalTrans.edit')">
                      <i class="ri-pencil-fill"></i>
                    </router-link>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-4" size="xl" hide-footer ok-only ok-variant="danger">
      <template #modal-title>
        {{ $t('teaGarden.return_one') + ' ' + $t('globalTrans.details') }}
        <b-button variant="primary" class="mr-2 float-right" @click="pdfExport">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <Details :id="detailsItemId" :key="detailsItemId" ref="details"/>
    </b-modal>
  </div>
</template>
<script>
import Details from './Details'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnOneListApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import teaGardenService from '@/mixins/tea-garden-service'

export default {
  mixins: [ModalBaseMasterList, teaGardenService],
  components: { Details },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      valid: null,
      sortBy: '',
      sortDirection: 'desc',
      sortDesc: true,
      isGardenAdmin: false,
      detailsItemId: 0,
      search: {
        return_type: this.$route.query.return_type ? this.$route.query.return_type : 0,
        garden_fact_id: 0,
        year: 0,
        area_type_id: 0,
        division_id: 0,
        district_id: 0,
        city_corporation_id: 0,
        upazila_id: 0,
        pauroshoba_id: 0,
        union_id: 0,
        status: this.$route.query.status ? this.$route.query.status : 0,
        limit: this.$route.query.limit ? this.$route.query.limit : 100
      },
      gardenList: [],
      districtList: [],
      upazilaList: [],
      cityCorporationList: [],
      unionList: [],
      pauroshobaList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
        { labels: 'globalTrans.year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: 'auto' } },
        { labels: 'teaGarden.garden_factory_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: 'auto' } },
        { labels: 'teaGarden.garden_factory_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.type', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: 'auto' } },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 9, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 10, thStyle: { width: '12%' } }
      ]
    }
  },
  created () {
    if (this.isGardenAdminCheckGardenId()) {
      this.isGardenAdmin = true
      // this.search.user_id = this.$store.state.Auth.authUser.user_id
      const gardenData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === this.isGardenAdminCheckGardenId())
      this.search.return_type = gardenData.type_garden_factory_id
      this.search.garden_fact_id = this.isGardenAdminCheckGardenId()
    } else {
      this.search.return_type = 1
    }
    this.loadData()
  },
  watch: {
    'search.return_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.gardenFactList()
      }
    },
    'search.area_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.search.garden_fact_id = 0
        this.gardenList = this.getAreaWiseGardenList(newVal)
      }
    },
    'search.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.garden_fact_id = 0
        this.districtList = this.getDistrictList(newVal)
        this.gardenList = this.getDivisionWiseGardenList(newVal)
      } else {
        this.districtList = []
      }
    },
    'search.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.garden_fact_id = 0
        this.upazilaList = this.getUpazilaList(newVal)
        this.cityCorporationList = this.getcityCorporationList(newVal)
        this.gardenList = this.getDistrictWiseGardenList(newVal)
      } else {
        this.upazilaList = []
        this.cityCorporationList = []
      }
    },
    'search.upazila_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search.garden_fact_id = 0
        this.unionList = this.getUnionList(newVal)
        this.pauroshobaList = this.getPauroshobaList(newVal)
        this.gardenList = this.getUpazilaWiseGardenList(newVal)
      } else {
        this.unionList = []
        this.pauroshobaList = []
      }
    },
    'search.city_corporation_id': function (newVal, oldVal) {
      this.search.garden_fact_id = 0
      this.gardenList = this.getCityCorporationWiseGardenList(newVal)
    },
    'search.pauroshoba_id': function (newVal, oldVal) {
      this.search.garden_fact_id = 0
      this.gardenList = this.getPauroshobaWiseGardenList(newVal)
    },
    'search.union': function (newVal, oldVal) {
      this.search.garden_fact_id = 0
      this.gardenList = this.getUnionWiseGardenList(newVal)
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.search.area_type_id) {
          this.gardenList = this.getAreaWiseGardenList(this.search.area_type_id)
        }
        if (this.search.division_id) {
          this.districtList = this.getDistrictList(this.search.division_id)
          this.gardenList = this.getDivisionWiseGardenList(this.search.division_id)
        }
        if (this.search.district_id) {
          this.upazilaList = this.getUpazilaList(this.search.district_id)
          this.cityCorporationList = this.getcityCorporationList(this.search.district_id)
          this.gardenList = this.getDistrictWiseGardenList(this.search.district_id)
        }
        if (this.search.upazila_id) {
          this.unionList = this.getUnionList(this.search.upazila_id)
          this.pauroshobaList = this.getPauroshobaList(this.search.upazila_id)
          this.gardenList = this.getUpazilaWiseGardenList(this.search.upazila_id)
        }
        if (this.search.city_corporation_id) {
          this.gardenList = this.getCityCorporationWiseGardenList(this.search.city_corporation_id)
        }
        if (this.search.pauroshoba_id) {
          this.gardenList = this.getPauroshobaWiseGardenList(this.search.pauroshoba_id)
        }
        if (this.search.union_id) {
          this.gardenList = this.getUnionWiseGardenList(this.search.union_id)
        }
      }
    }
  },
  computed: {
    masterTeaGardenGenInfoList () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.status === 1)
    },
    masterBoughtLeafFactoryList () {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.status === 1)
    },
    yearList () {
      return this.$store.state.TeaGardenService.commonObj.yearList.map(el => {
        return Object.assign({ value: el, text: this.$i18n.locale === 'en' ? el : this.$n(el, { useGrouping: false }) })
      })
    },
    returnTypeList () {
      return [
          { text: this.$i18n.locale === 'bn' ? 'বাগান' : 'Garden', value: 1 },
          { text: this.$i18n.locale === 'bn' ? 'কারখানা' : 'Factory', value: 2 }
      ]
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'year' },
          { key: 'garden_factory_id' },
          { key: 'garden_factory_name_bn' },
          { key: 'type_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'year' },
          { key: 'garden_factory_id' },
          { key: 'garden_factory_name' },
          { key: 'type_en' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    getAreaTypeList: function () {
      return this.$store.state.commonObj.areaTypeList.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    divisionList () {
      return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
    }
  },
  methods: {
    detailsData (item) {
      this.detailsItemId = item.id
    },
    gardenFactList () {
      if (this.search.return_type === 1) {
        this.gardenList = this.masterTeaGardenGenInfoList
      } else {
        this.gardenList = this.masterBoughtLeafFactoryList
      }
    },
    getDistrictList (id) {
      return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1 && item.division_id === id)
    },
    getUpazilaList (id) {
      return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1 && item.district_id === id)
    },
    getcityCorporationList (id) {
      return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === id)
    },
    getUnionList (upazilaId) {
      return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1 && item.upazila_id === upazilaId)
    },
    getPauroshobaList (id) {
      return this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.status === 1 && item.upazila_id === id)
    },
    /** Garden List filter */
    getAreaWiseGardenList (id) {
      if (this.search.return_type === 1) {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.area_type_id === id)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.area_type_id === id)
      }
    },
    getDivisionWiseGardenList (id) {
      if (this.search.return_type === 1) {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.division_id === id)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.division_id === id)
      }
    },
    getDistrictWiseGardenList (id) {
      if (this.search.return_type === 1) {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.district_id === id)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.district_id === id)
      }
    },
    getUpazilaWiseGardenList (id) {
      if (this.search.return_type === 1) {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.upazila_id === id)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.upazila_id === id)
      }
    },
    getCityCorporationWiseGardenList (id) {
      if (this.search.return_type === 1) {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.city_corporation_id === id)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.city_corporation_id === id)
      }
    },
    getPauroshobaWiseGardenList (id) {
      if (this.search.return_type === 1) {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.pauroshoba_id === id)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.pauroshoba_id === id)
      }
    },
    getUnionWiseGardenList (id) {
      if (this.search.return_type === 1) {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.union_id === id)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.union_id === id)
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, returnOneListApi, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const arrayList = item.return_type === 1 ? this.masterTeaGardenGenInfoList : this.masterBoughtLeafFactoryList
          const tmpType = this.$store.state.TeaGardenService.commonObj.returnTypeList.find(el => el.value === item.return_type)
          const garden = arrayList.find(data => data.value === parseInt(item.garden_fact_id))
          const gardData = {
            garden_factory_id: item.return_type === 1 ? garden.garden_id_no : garden.registration_no,
            garden_factory_name: garden !== undefined ? garden.text_en : '',
            garden_factory_name_bn: garden !== undefined ? garden.text_bn : '',
            type_en: tmpType !== undefined ? tmpType.text_en : '',
            type_bn: tmpType !== undefined ? tmpType.text_bn : ''
          }
          return Object.assign({}, item, { serial: index }, gardData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    pdfExport () {
      this.$refs.details.pdfExport()
    }
  }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
