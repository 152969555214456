<template>
    <b-row>
      <!-- <b-col lg="12" sm="12"> -->
        <b-overlay :show="loading">
          <b-row>
            <b-col lg="12" xs="12" md="12">
                <b-row>
                    <b-col lg="12" xs="12" md="12">
                      <list-report-head :base-url="baseUrl" uri="/configuration/report-heading/detail" :org-id="3">
                        <template v-slot:projectNameSlot>
                          <h3>{{ $i18n.locale === 'en' ? 'Bangladesh Tea Board' : 'বাংলাদেশ চা বোর্ড' }}</h3>
                        </template>
                        {{ $i18n.locale === 'en' ? 'Return on Land Use, Tea Seeds, Plants & Tea Waste' : 'ভূমি ব্যবহার, চা গাছ, বীজ ও চা জাবরা সংক্রান্ত-রিটার্ণ' }}
                      </list-report-head>
                    </b-col>
                </b-row>
                <b-row v-if="detailsData">
                    <b-col style="font-size: 14px" sm="4">
                      <div>
                        <span v-if="$i18n.locale === 'en'">1. Name of the tea Garden/factory<br>and address:</span>
                        <span v-else>১। বাগান/কারখানার নাম <br>ও ঠিকানাঃ </span>
                        <span><b> {{ getGardenFactName(detailsData.return_type, detailsData.garden_fact_id) + ', ' }}<br>{{ (currentLocale == 'en' ? detailsData.garden.garden_address_en : detailsData.garden.garden_address_bn) + ', ' + getAddress(detailsData.return_type) }}</b></span>
                      </div>
                      <br>
                      <div>
                        <span>{{ $i18n.locale === 'en' ? 'e-mail: ' : 'ই-মেইলঃ '}}</span>
                        <span><b>{{ detailsData.return_type == 1 ? detailsData.garden.email : '' }}</b></span>
                      </div>
                    </b-col>
                    <b-col sm="4">
                        <!-- <div class="text-center font-weight-bold">{{ $i18n.locale === 'en' ? 'Return on Land Use, Tea Seeds, Plants & Tea Waste' : 'ভূমি ব্যবহার, চা গাছ, বীজ ও চা জাবরা সংক্রান্ত-রিটার্ণ' }}</div> -->
                        <div class="text-center">{{ $i18n.locale === 'en' ? '(For the year ending '+ '31-12-' + detailsData.year + ')' : '(' + ('৩১-১২-') + ($n(detailsData.year, { useGrouping: false })) + ' এ সমাপ্ত বৎসরের জন্য)' }}</div>
                        <div class="text-center mt-3">{{ $i18n.locale === 'en' ? 'Section-1: Particular of land use' : 'সেকশন-১ঃ ভূমি ব্যবহারের তথ্যাদি'}}</div>
                    </b-col>
                    <b-col style="font-size: 14px" sm="4">
                      <div>
                        <span v-if="$i18n.locale === 'en'">2. Name of the Managing Agent/Proprietor & address (Phone No. if any): </span>
                        <span v-else>২। ম্যানেজিং এজেন্ট / মালিকের নাম ও ঠিকানা (ফোন নং যদি থাকে) </span>
                        <span v-if="detailsData.return_type == 1"><b>{{ (currentLocale == 'en' ? detailsData.garden.owner_name_en : detailsData.garden.owner_name_bn) + ', '}} <br>{{(currentLocale == 'en' ? detailsData.garden.garden_address_en : detailsData.garden.garden_address_bn) + ', ' + getAddress(detailsData.return_type) }}</b></span>
                        <span v-else></span>
                      </div>
                      <br>
                      <div>{{ $t('globalTrans.mobile_no') + ': ' }} <span><b>{{ detailsData.garden.mobile | mobileNumber }}</b></span></div>
                    </b-col>
                </b-row>
            </b-col>
          </b-row>
          <b-row v-if="detailsData">
            <!-- Tab - One -->
            <b-col lg="12" xs="12" md="12">
                <b-table-simple bordered style="font-size: 14px">
                    <b-thead>
                        <b-tr>
                          <b-th class="align-middle text-center" colspan="11">{{ $t('teaGarden.area_under_tea') }}</b-th>
                        </b-tr>
                        <b-tr>
                          <b-th class="align-middle text-center" colspan="4">{{ $t('teaGarden.area_under_seeds_and_nurseries') }}</b-th>
                          <b-th class="align-middle text-center" colspan="6">{{ $t('teaGarden.area_under_tea_cultivation') }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 4+10)' : '(কলাম ৪+১০)' }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $t('teaGarden.seed_bari') }}</b-th>
                          <b-th>{{ $t('teaGarden.seed_nurseries') }}</b-th>
                          <b-th>{{ $t('teaGarden.clone_nurseries') }}</b-th>
                          <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 1+2+3)' : '(কলাম ১+২+৩)' }}</b-th>
                          <b-th>{{ $t('teaGarden.immature_tea') }}</b-th>
                          <b-th>{{ $t('teaGarden.bushes_six_ten') }}</b-th>
                          <b-th>{{ $t('teaGarden.bushes_eleven_forty') }}</b-th>
                          <b-th>{{ $t('teaGarden.bushes_fortyone_sixty') }}</b-th>
                          <b-th>{{ $t('teaGarden.bushes_above_sixty') }}</b-th>
                          <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 5+6+7+8+9)' : '(কলাম ৫+৬+৭+৮+৯)' }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $n(1) }}</b-th>
                          <b-th>{{ $n(2) }}</b-th>
                          <b-th>{{ $n(3) }}</b-th>
                          <b-th>{{ $n(4) }}</b-th>
                          <b-th>{{ $n(5) }}</b-th>
                          <b-th>{{ $n(6) }}</b-th>
                          <b-th>{{ $n(7) }}</b-th>
                          <b-th>{{ $n(8) }}</b-th>
                          <b-th>{{ $n(9) }}</b-th>
                          <b-th>{{ $n(10) }}</b-th>
                          <b-th>{{ $n(11) }}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="text-center align-middle">
                          <b-td>{{ $n(detailsData.seed_bari, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.seed_nurse, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.clone_nurse, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.sub_total_a, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.immature_tea, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.bushes_six_ten, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.bushes_eleven_forty, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.bushes_fortyone_sixty, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.bushes_above_sixty, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.sub_total_aa, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n((parseFloat(detailsData.sub_total_a) + parseFloat(detailsData.sub_total_aa)), { minimumFractionDigits: 2 }) }}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <!-- Tab - One - End -->
            <!-- Tab - Two -->
            <b-col v-if="detailsData.tab_two" lg="12" xs="12" md="12" class="mt-4">
                <b-table-simple bordered style="font-size: 14px">
                    <b-thead>
                        <b-tr>
                          <b-th class="align-middle text-center" colspan="14">{{ $t('teaGarden.area_under_other') }}</b-th>
                        </b-tr>
                        <b-tr>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.rubber') }}</b-th>
                          <b-th class="align-middle text-center" colspan="9">{{ $t('teaGarden.forest') }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 12+16+17+18+19+20+21)' : '(কলাম ১২+১৬+১৭+১৮+১৯+২০+২১)' }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.fallow_land') }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.stream') }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.masjid') }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $t('teaGarden.bamboo') }}</b-th>
                          <b-th>{{ $t('teaGarden.planted_forest') }}</b-th>
                          <b-th>{{ $t('teaGarden.natural_forest') }}</b-th>
                          <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 13+14+15)' : '(কলাম ১৩+১৪+১৫)' }}</b-th>
                          <b-th>{{ $t('teaGarden.sungrass') }}</b-th>
                          <b-th>{{ $t('teaGarden.paddy_land') }}</b-th>
                          <b-th>{{ $t('teaGarden.jackfruit') }}</b-th>
                          <b-th>{{ $t('teaGarden.lemon') }}</b-th>
                          <b-th>{{ $t('teaGarden.other_crop') }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $n(12) }}</b-th>
                          <b-th>{{ $n(13) }}</b-th>
                          <b-th>{{ $n(14) }}</b-th>
                          <b-th>{{ $n(15) }}</b-th>
                          <b-th>{{ $n(16) }}</b-th>
                          <b-th>{{ $n(17) }}</b-th>
                          <b-th>{{ $n(18) }}</b-th>
                          <b-th>{{ $n(19) }}</b-th>
                          <b-th>{{ $n(20) }}</b-th>
                          <b-th>{{ $n(21) }}</b-th>
                          <b-th>{{ $n(22) }}</b-th>
                          <b-th>{{ $n(23) }}</b-th>
                          <b-th>{{ $n(24) }}</b-th>
                          <b-th>{{ $n(25) }}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="text-center align-middle">
                          <b-td>{{ $n(detailsData.tab_two.rubber, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.bamboo, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.planted_forest, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.natural_forest, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n((parseFloat(detailsData.tab_two.bamboo) + parseFloat(detailsData.tab_two.planted_forest) + parseFloat(detailsData.tab_two.natural_forest)), { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.sungrass, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.paddy_land, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.jackfruit, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.lemon, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.other_crop, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n((parseFloat(detailsData.tab_two.sub_total_b) - (parseFloat(detailsData.tab_two.bamboo) + parseFloat(detailsData.tab_two.planted_forest) + parseFloat(detailsData.tab_two.natural_forest))), { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.fallow_land, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.stream, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.masjid, { minimumFractionDigits: 2 }) }}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <!-- Tab - Three - End -->
            <b-col v-if="detailsData.tab_two" lg="12" xs="12" md="12" class="mt-4">
                <b-table-simple bordered style="font-size: 14px">
                    <b-thead>
                        <b-tr>
                          <b-th class="align-middle text-center" colspan="6">{{ $t('teaGarden.other_non_agriculture') }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ currentLocale == 'en' ? 'Total Area of Grant' : 'বাগানের ইজারাকৃত মোট অনুমোদিত এলাকা' }}<br> {{currentLocale == 'en' ? '(Col. 11+22+31)' : '(কলাম ১১+২২+৩১)' }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.leased_date') }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.tax_date') }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('globalTrans.remarks') }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $t('teaGarden.graveyard') }}</b-th>
                          <b-th>{{ $t('teaGarden.labor_line') }}</b-th>
                          <b-th>{{ $t('teaGarden.factory') }}</b-th>
                          <b-th>{{ $t('teaGarden.school') }}</b-th>
                          <b-th>{{ $t('teaGarden.sect_road') }}</b-th>
                          <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 23+24+25+26+27+28+29+30)' : '(কলাম ২৩+২৪+২৫+২৬+২৭+২৮+২৯+৩০)' }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $n(26) }}</b-th>
                          <b-th>{{ $n(27) }}</b-th>
                          <b-th>{{ $n(28) }}</b-th>
                          <b-th>{{ $n(29) }}</b-th>
                          <b-th>{{ $n(30) }}</b-th>
                          <b-th>{{ $n(31) }}</b-th>
                          <b-th>{{ $n(32) }}</b-th>
                          <b-th>{{ $n(33) }}</b-th>
                          <b-th>{{ $n(34) }}</b-th>
                          <b-th>{{ $n(35) }}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="text-center align-middle">
                          <b-td>{{ $n(detailsData.tab_two.graveyard, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.labor_line, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.factory, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.school, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.sect_road, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_two.sub_total_bb, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(((parseFloat(detailsData.sub_total_a) + parseFloat(detailsData.sub_total_aa)) + (parseFloat(detailsData.tab_two.sub_total_b) - (parseFloat(detailsData.tab_two.bamboo) + parseFloat(detailsData.tab_two.planted_forest) + parseFloat(detailsData.tab_two.natural_forest))) + parseFloat(detailsData.tab_two.sub_total_bb)), { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td v-if="detailsData.tab_two.leased_date">{{ detailsData.tab_two.leased_date | dateFormat }}</b-td>
                          <b-td v-else></b-td>
                          <b-td v-if="detailsData.tab_two.tax_date">{{ detailsData.tab_two.tax_date | dateFormat }}</b-td>
                          <b-td v-else></b-td>
                          <b-td></b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>

            <!-- Section - 2  -->
            <b-col v-if="detailsData.tab_three" lg="12" xs="12" md="12" class="mt-4">
                <div class="text-center mb-3">{{ $i18n.locale === 'en' ? 'Section-2: Extension Activities During the Season' : 'সেকশন-২ঃ চা বাগানের সম্প্রসারণ কাজসমূহ'}}</div>
                <b-table-simple bordered style="font-size: 14px">
                    <b-thead>
                        <b-tr>
                          <b-th class="align-middle text-center" colspan="3">{{ $t('teaGarden.plantation_added') }}</b-th>
                          <b-th class="align-middle text-center" colspan="3">{{ $t('teaGarden.plantation_decreased') }}</b-th>
                          <b-th class="align-middle text-center" colspan="4">{{ $t('teaGarden.scope_extension') }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $t('teaGarden.extension') }}</b-th>
                          <b-th>{{ $t('teaGarden.replanting') }}</b-th>
                          <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 1+2)' : '(কলাম ১+২)' }}</b-th>
                          <b-th>{{ $t('teaGarden.abandoned_area') }}</b-th>
                          <b-th>{{ $t('teaGarden.uprooted_area') }}</b-th>
                          <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 4+5)' : '(কলাম ৪+৫)' }}</b-th>
                          <b-th>{{ $t('teaGarden.ava_area') }}</b-th>
                          <b-th>{{ $t('teaGarden.area_no') }}</b-th>
                          <b-th>{{ $t('teaGarden.extension_scope') }}</b-th>
                          <b-th>{{ $t('globalTrans.remarks') }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $n(1) }}</b-th>
                          <b-th>{{ $n(2) }}</b-th>
                          <b-th>{{ $n(3) }}</b-th>
                          <b-th>{{ $n(4) }}</b-th>
                          <b-th>{{ $n(5) }}</b-th>
                          <b-th>{{ $n(6) }}</b-th>
                          <b-th>{{ $n(7) }}</b-th>
                          <b-th>{{ $n(8) }}</b-th>
                          <b-th>{{ $n(9) }}</b-th>
                          <b-th>{{ $n(10) }}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="text-center align-middle">
                          <b-td>{{ $n(detailsData.tab_three.extension, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_three.replanting, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_three.sub_total_c, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_three.abandoned_area, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_three.uprooted_area, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_three.sub_total_cc, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_three.ava_area, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_three.area_no, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_three.extension_scope, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td></b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>

            <!-- Section - 3  -->
            <b-col v-if="detailsData.tab_four" lg="12" xs="12" md="12" class="mt-4">
                <div class="text-center mb-3">{{ $i18n.locale === 'en' ? 'Section-3: Extension Activities During the Season' : 'সেকশন-৩ঃ চা বীজ ও গাছ সম্পর্কিত তথ্যাদি'}}</div>
                <b-table-simple bordered style="font-size: 14px">
                    <b-thead>
                        <b-tr>
                          <b-th class="align-middle text-center" colspan="10">{{ $t('teaGarden.tea_seeds') }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2" colspan="3">{{ $t('teaGarden.tea_plants') }}</b-th>
                        </b-tr>
                        <b-tr>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.produced') }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.used') }}</b-th>
                          <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.surplus') }}</b-th>
                          <b-th class="align-middle text-center" colspan="3">{{ $t('teaGarden.disposal_surplus') }}</b-th>
                          <b-th class="align-middle text-center" colspan="4">{{ $t('teaGarden.deficit_met') }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $t('teaGarden.sold') }}</b-th>
                          <b-th>{{ $t('teaGarden.disposed_otherwise') }}</b-th>
                          <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 4+5)' : '(কলাম ৪+৫)' }}</b-th>
                          <b-th>{{ $t('teaGarden.purchased') }}</b-th>
                          <b-th>{{ $t('teaGarden.conn_otherwise') }}</b-th>
                          <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 7+8)' : '(কলাম ৭+৮)' }}</b-th>
                          <b-th>{{ $t('teaGarden.estim_requirement') }}</b-th>
                          <b-th>{{ $t('teaGarden.area_season') }}</b-th>
                          <b-th>{{ $t('teaGarden.plants_infilled') }}</b-th>
                          <b-th>{{ $t('teaGarden.avg_plants') }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $n(1) }}</b-th>
                          <b-th>{{ $n(2) }}</b-th>
                          <b-th>{{ $n(3) }}</b-th>
                          <b-th>{{ $n(4) }}</b-th>
                          <b-th>{{ $n(5) }}</b-th>
                          <b-th>{{ $n(6) }}</b-th>
                          <b-th>{{ $n(7) }}</b-th>
                          <b-th>{{ $n(8) }}</b-th>
                          <b-th>{{ $n(9) }}</b-th>
                          <b-th>{{ $n(10) }}</b-th>
                          <b-th>{{ $n(11) }}</b-th>
                          <b-th>{{ $n(12) }}</b-th>
                          <b-th>{{ $n(13) }}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="text-center align-middle">
                          <b-td>{{ $n(detailsData.tab_four.produced, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.used, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.surplus, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.sold, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.disposed_otherwise, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.sub_total_d, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.purchased, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.conn_otherwise, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.sub_total_dd, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.estim_requirement, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.area_season, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.plants_infilled, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_four.avg_plants, { minimumFractionDigits: 2 }) }}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>

            <!-- Section - 4  -->
            <b-col v-if="detailsData.tab_five" lg="12" xs="12" md="12" class="mt-4">
                <div class="text-center mb-3">{{ $i18n.locale === 'en' ? 'Section-4: Tea Waste' : 'সেকশন-৪ঃ চা জাবরা'}}</div>
                <b-table-simple bordered style="font-size: 14px">
                    <b-thead>
                        <b-tr>
                          <b-th class="align-middle text-center">{{ $t('teaGarden.stock_inhand') }}</b-th>
                          <b-th class="align-middle text-center">{{ $t('teaGarden.collection') }}</b-th>
                          <b-th class="align-middle text-center">{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 1+2)' : '(কলাম ১+২)' }}</b-th>
                          <b-th class="align-middle text-center">{{ $t('teaGarden.qty_disposed') }}</b-th>
                          <b-th class="align-middle text-center">{{ $t('teaGarden.qty_destroyed') }}</b-th>
                          <b-th class="align-middle text-center">{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 4+5)' : '(কলাম ৪+৫)' }}</b-th>
                          <b-th class="align-middle text-center">{{ $t('teaGarden.balance') }}<br> {{currentLocale == 'en' ? '(Col. 3-6)' : '(কলাম ৩-৬)' }}</b-th>
                        </b-tr>
                        <b-tr class="text-center align-middle">
                          <b-th>{{ $n(1) }}</b-th>
                          <b-th>{{ $n(2) }}</b-th>
                          <b-th>{{ $n(3) }}</b-th>
                          <b-th>{{ $n(4) }}</b-th>
                          <b-th>{{ $n(5) }}</b-th>
                          <b-th>{{ $n(6) }}</b-th>
                          <b-th>{{ $n(7) }}</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="text-center align-middle">
                          <b-td>{{ $n(detailsData.tab_five.stock_inhand, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_five.collection, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_five.sub_total_e, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_five.qty_disposed, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_five.qty_destroyed, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n(detailsData.tab_five.sub_total_ee, { minimumFractionDigits: 2 }) }}</b-td>
                          <b-td>{{ $n((detailsData.tab_five.sub_total_e - detailsData.tab_five.sub_total_ee), { minimumFractionDigits: 2 }) }}</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
          </b-row>
        </b-overlay>
      <!-- </b-col> -->
    </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnOneDetailsApi } from '../../api/routes'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
import AddressHelper from '@/utils/area-type-address'

export default {
    name: 'Details',
    props: ['id'],
    components: { ListReportHead },
    data () {
      return {
        AddressHelper: AddressHelper,
        baseUrl: teaGardenServiceBaseUrl,
        loading: false,
        detailsData: null
      }
    },
    computed: {
      masterTeaGardenGenInfoList () {
          return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.status === 1)
      },
      masterBoughtLeafFactoryList () {
          return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.status === 1)
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    created () {
      if (this.id) {
        this.loading = true
        this.getDetailsData()
      }
    },
    methods: {
      getAddress (type) {
        if (parseInt(type) === 1) {
          return this.AddressHelper.getCommonAddress(this.detailsData.garden)
        } else {
          return this.AddressHelper.getCommonAddress(this.detailsData.factory)
        }
      },
      getGardenFactName (type, Id) {
        const List = parseInt(type) === 1 ? this.masterTeaGardenGenInfoList : this.masterBoughtLeafFactoryList
        const Obj = List.find(el => el.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
            return this.currentLocale === 'en' ? Obj.text_en : Obj.text_bn
        }
        return ''
      },
      BangNum (n) {
         if (this.$i18n.locale === 'bn') {
            return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
         }
         return n
      },
      async getDetailsData () {
        const resultData = await RestApi.getData(teaGardenServiceBaseUrl, returnOneDetailsApi, { app_id: this.id })
        if (resultData.success) {
          this.detailsData = resultData.data
          this.loading = false
        } else {
          this.detailsData = null
          this.loading = false
        }
      },
      async pdfExport () {
        this.loader = true
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, id: this.id })
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 25)
            if (service !== undefined) {
              if (service.office_type_id) {
                params.office_type_id = service.office_type_id
              }
              if (service.office_id) {
                params.office_id = service.office_id
              }
            }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, returnOneDetailsApi, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.loader = false
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
}
</script>
<style scoped>
.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 1px !important;
    border-color: #333 !important;
}
.table-bordered tbody th, .table-bordered tbody td {
    border-bottom-width: 1px !important;
    border-color: #333 !important;
}
.table th, .table td {
    padding: 0.25rem !important;
}
</style>
